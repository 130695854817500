export const getSubmissions = (builder) => {
  return builder.query({
    query: ({ assignmentId, includes, page, limit, query }) => ({
      url: '/submissions.list',
      method: 'POST',
      body: {
        assignmentId,
        includes,
        query,
      },
      params: {
        limit,
        offset: (page - 1) * limit,
      },
    }),
    transformResponse: (response) => {
      return response.data
    },
    providesTags: (result) =>
      // is result available?
      result
        ? // successful query
          [
            ...result.map(({ id }) => ({ type: 'Submission', id })),
            { type: 'Submission', id: 'LIST' },
          ]
        : // an error occurred, but we still want to refetch this query when `{ type: 'Submission', id: 'LIST' }` is invalidated
          [{ type: 'Submission', id: 'LIST' }],
  })
}

export const getSubmissionById = (builder) => {
  return builder.query({
    query: ({ id, includes }) => ({
      url: `/submissions.info`,
      method: 'POST',
      body: { id, includes },
    }),
    transformResponse: (response) => response.data,
    providesTags: (result, error, id) => [{ type: 'Submission', id }],
  })
}

export const getSubmissionByAssignmentIdAndUserId = (builder) => {
  return builder.query({
    query: ({ userId, assignmentId, enrollmentId, includes }) => ({
      url: '/submissions.infoByAssignmentAndUserId',
      method: 'POST',
      body: {
        userId,
        assignmentId,
        enrollmentId,
        includes,
      },
    }),
  })
}

export const createSubmission = (builder) => {
  return builder.mutation({
    query: (submission) => ({
      url: '/submissions.create',
      method: 'POST',
      body: submission,
    }),
    invalidatesTags: [{ type: 'Submission', id: 'LIST' }],
  })
}

export const updateSubmission = (builder) => {
  return builder.mutation({
    query: (submission) => ({
      // TODO: query: ({id, ...body})
      url: '/submissions.update',
      method: 'POST',
      body: submission,
    }),
    transformResponse: (response) => response.data,
    invalidatesTags: (result, error, submission) => [
      { type: 'Submission', id: submission.id },
    ],
  })
}

export const deleteSubmission = (builder) => {
  return builder.mutation({
    query: ({ id }) => ({
      url: '/submissions.delete',
      method: 'POST',
      body: { id },
    }),
    invalidatesTags: (result, error, { id }) => [{ type: 'Submission', id }],
  })
}

export default {
  getSubmissions,
  getSubmissionById,
  getSubmissionByAssignmentIdAndUserId,
  createSubmission,
  updateSubmission,
  deleteSubmission,
}
