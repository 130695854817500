import { createSlice } from '@reduxjs/toolkit'
export const uiSlice = createSlice({
  name: 'ui',
  initialState: {
    queryParams: {
      user_id: '',
      assigment_id: '',
    },
    isStudent: true,
    studentAnsweredForum: false,
    selectedPost: null,
    postsDisplay: 'cards',
    authenticated: false,
    selectedExam: null,
    reloadExamSubmissions: false,
    selectedFile: null,
    reloadPosts: false,
    isFullScreen: localStorage.getItem('isFullScreen') || false,
    currentDocument: null,
    selectedAssignment: null,
    selectedSubmission: null,
    enrollmentId: null,
    enrollmentGroupId: null,
  },
  reducers: {
    setQueryParams: (state, action) => {
      state.queryParams = action.payload
    },
    setIsStudent: (state, action) => {
      state.isStudent = action.payload
    },
    setSelectedPost: (state, action) => {
      state.selectedPost = action.payload
    },
    setPostsDisplay: (state, action) => {
      state.postsDisplay = action.payload
    },
    setAuthenticated: (state, action) => {
      state.authenticated = action.payload
    },
    setStudentAnsweredForum: (state, action) => {
      state.studentAnsweredForum = action.payload
    },
    setSelectedExam: (state, action) => {
      state.selectedExam = action.payload
    },
    setReloadExamSubmissions: (state, action) => {
      state.reloadExamSubmissions = action.payload
    },
    setSelectedFile: (state, action) => {
      state.selectedFile = action.payload
    },
    setReloadPosts: (state, action) => {
      state.reloadPosts = action.payload
    },
    setIsFullScreenActive: (state, action) => {
      localStorage.setItem('isFullScreen', action.payload)
      state.isFullScreen = action.payload
    },
    setCurrentDocument: (state, action) => {
      state.currentDocument = action.payload
    },
    setSelectedAssignment: (state, action) => {
      state.selectedAssignment = action.payload
    },
    setSelectedSubmission: (state, action) => {
      state.selectedSubmission = action.payload
    },
    setEnrollmentId: (state, action) => {
      state.enrollmentId = action.payload
    },
    setEnrollmentGroupId: (state, action) => {
      state.enrollmentGroupId = action.payload
    },
    setCourseId: (state, action) => {
      state.courseId = action.payload
    },
  },
})

export const selectQueryParams = (state) => state.ui.queryParams
export const selectUserId = (state) => state.ui.queryParams.user_id
export const selectIsStudent = (state) => state.ui.isStudent
export const selectSelectedPost = (state) => state.ui.selectedPost
export const selectPostsDisplay = (state) => state.ui.postsDisplay
export const selectAuthenticated = (state) => state.ui.authenticated
export const selectStudentAnsweredForum = (state) =>
  state.ui.studentAnsweredForum
export const selectSelectedExam = (state) => state.ui.selectedExam
export const selectReloadExamSubmissions = (state) =>
  state.ui.reloadExamSubmissions
export const selectSelectedFile = (state) => state.ui.selectedFile
export const selectReloadPosts = (state) => state.ui.reloadPosts
export const selectIsFullScreenActive = (state) => {
  if (typeof state.ui.isFullScreen === 'string') {
    return state.ui.isFullScreen === 'true'
  }

  return state.ui.isFullScreen
}
export const selectCurrentDocument = (state) => state.ui.currentDocument
export const selectSelectedAssignment = (state) => state.ui.selectedAssignment
export const selectSelectedSubmission = (state) => state.ui.selectedSubmission
export const selectEnrollmentId = (state) => state.ui.enrollmentId
export const selectEnrollmentGroupId = (state) => state.ui.enrollmentGroupId
export const selectCourseId = (state) => state.ui.courseId
export const {
  setQueryParams,
  setIsStudent,
  setSelectedPost,
  setPostsDisplay,
  setAuthenticated,
  setStudentAnsweredForum,
  setSelectedExam,
  setReloadExamSubmissions,
  setSelectedFile,
  setReloadPosts,
  addMatrixIndex,
  setIsFullScreenActive,
  setCurrentDocument,
  setSelectedAssignment,
  setSelectedSubmission,
  setEnrollmentId,
  setEnrollmentGroupId,
  setCourseId,
} = uiSlice.actions

export default uiSlice.reducer
